import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ModalController } from '@ionic/angular';
//import { SocialSharing } from '@ionic-native/social-sharing/ngx';
//import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent implements OnInit {

  mobile = false;

  public sharingList = environment.socialShareOption;

  loader: any = null;
  sharingText = 'You can download our app from playstore or use this link to download the app. And you can share awesome coupons with your loved once, Thank you';
  emailSubject = 'Download Apps'
  recipent = ['recipient@example.org'];
  sharingImage = ['https://store.enappd.com/wp-content/uploads/2019/03/700x700_2-1-280x280.jpg'];
  sharingUrl = 'https://store.enappd.com';

  constructor(
    private modal: ModalController,
    //private socialSharing: SocialSharing
  ) { }

  ngOnInit() { }

  closeModal() {
    this.modal.dismiss();
  }

  async shareItem(item: any) {
    //if (navigator.canShare) {
    if (navigator.share) {

      let content = item.href2;

      content = content.replace('[SUBJECT]', 'SMALO Content Sharing');
      content = content.replace('[BODY]', 'Schau mal, habe einen interessanten Inhalt auf SMALO gefunden...');
      if (content.indexOf(' ') >= 0) {
        //content = encodeURI(content);
      }

      console.log('content=', content);

      navigator.share({
        title: 'SMALO Content Sharing',
        text: 'Schau mal, habe einen interessanten Inhalt auf SMALO gefunden...',
        //url: content
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing ', error));

    }
  }

  async shareVia(shareData) {

    console.log('shareData=', shareData);

    await Share.share({
      title: 'See cool stuff',
      text: 'Really awesome thing you need to see right meow',
      url: 'http://ionicframework.com/',
      dialogTitle: 'Share with buddies',
    });

    /*
    if (shareData.shareType === 'viaEmail') {
      this.shareViaEmail();
    } else {
      this.socialSharing[`${shareData.shareType}`](this.sharingText, this.sharingImage, this.sharingUrl)
        .then((res) => {
          this.modal.dismiss();
        })
        .catch((e) => {
          console.log('error', e)
          this.modal.dismiss();
        });
    }
    */
  }
  shareViaEmail() {
    /*this.socialSharing.canShareViaEmail().then((res) => {
      this.socialSharing.shareViaEmail(this.sharingText, this.emailSubject, this.recipent, null, null, this.sharingImage).then(() => {
        this.modal.dismiss();
      })
    }).catch((e) => {
      // Error!
    });
    */
  }

}
